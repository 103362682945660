.o-thumbnailPrice {
  @import 'a-flag';

  .m-price {
    display: table;

    &.-main {
      @include set-font-scale('09', 'm');
      @include set-font-face('semi-bold');
    }

    &.-secondary {
      @include set-font-scale('04', 'm');

      color: $color-grey-999;
      margin-bottom: $mu075;
    }

    &.-mainSub {
      @include set-font-scale('05', 'm');
      @include set-font-face('semi-bold');

      color: $color-grey-600;
    }

    &.-secondarySub {
      @include set-font-scale('04', 'm');

      color: $color-grey-600;
    }

    &.-light {
      @include set-font-scale('08', 'm');
    }

    &.-mainSub.-light,
    &.-secondary.-light,
    &.-secondarySub.-light {
      @include set-font-scale('04', 'm');
    }

    &.-mainSub.-light {
      @include set-font-scale('05', 'm');
    }

    &.-discount {
      color: $color-secondary-red-600;
    }

    &.-crossed {
      @include set-font-scale('04', 'm');

      display: inline-block;
      vertical-align: middle;
      color: $color-grey-600;
      text-decoration: line-through;
    }

    &__legals {
      @include set-font-face('regular');
      @include set-font-scale('02', 's');

      color: $color-grey-600;
      margin-top: $mu025;
    }
  }

  .m-price.-main .m-price__line {
    display: flex;
    margin-right: $mu025;
  }

  .m-price.-main .m-price__decimal {
    @include set-font-scale('07', 'm');

    display: flex;
    flex-wrap: nowrap;
    gap: $mu025;
  }

  .m-price.-main.-light .m-price__decimal {
    @include set-font-scale('06', 'm');
  }

  .m-price.-main .m-price__unit {
    @include set-font-scale('05', 'm');

    display: table-cell;
    vertical-align: bottom;
    padding-top: $mu025;
    padding-left: $mu025;
  }

  .m-price.-main.-light .m-price__unit {
    @include set-font-scale('04', 'm');

    padding-top: $mu025 / 2;
  }
}
