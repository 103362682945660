$mozaic-env: development;
@import '@mozaic-ds/styles/settings-tools/all-settings';
@import '@mozaic-ds/styles/components/c.stars-result';

@import "includes/price";
@import 'includes/stock-status';

/* ########################################
            MIXINS
   ######################################## */
@mixin thumbnail-price-policy {
  position: absolute;
  left: -#{$mu050};
  top: $mu100;
  z-index: 1;
}

@mixin edge-bleed-image {
  width: calc(100% + $mu200);
  height: auto;
  margin: -#{$mu100};
}

/* ########################################
            DEFAULT
   ######################################## */
.o-thumbnail {
  @import 'includes/a-flag';

  position: relative;

  &__details {
    display: flex;
    flex-direction: row;
  }

  &__flag {
    margin-bottom: $mu050;
  }

  &__brand {
    .lazy {
      height: $mu125;
      margin-bottom: $mu025;
    }

    .lazy-loaded {
      max-width: $mu500;
      margin-bottom: $mu025;
    }

  }

  &__infos {
    display: flex;
    flex-direction: column;
    margin-left: $mu100;
  }

  &__reviews {
    display: flex;
    flex-direction: row;
  }

  &__designation + &__reviews {
    margin-top: $mu025;
  }
  &__designation + &__vendor {
    margin-top: $mu075;
  }

  &__price-infos {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__price {
    .o-price {
      @include set-from-screen("m") {
        font-size: $size-font-08 !important;
      }
    }
  }

  &__add-to-cart {
    z-index: 1;
    margin-left: $mu100;
  }

  &__offers {
    margin-top: $mu025;
  }

  &__reviews,
  &__vendor,
  &__variants,
  &__legals,
  &__delivery {
    margin-top: $mu075;
  }

  .stock-status_badge {
    width: $mu050;
    height: $mu050;
  }
}

/* ########################################
            HORIZONTAL
   ######################################## */
.o-thumbnail--h {
  @include set-border-radius('l');

  border: 1px solid $color-grey-200;
  padding: $mu100;

  .o-thumbnail__price-infos {
    margin-top: $mu100;
  }

  .o-thumbnail__add-to-cart {
    position: relative;

    .product-thumbnails-add-to-cart-msg {
      position: absolute;
      top: 50%;
      right: $mu350;
      transform: translateY(-50%);
    }
  }
}

/* ########################################
            VERTICAL
   ######################################## */
.o-thumbnail--v {
  @include set-border-radius('l');

  border: 1px solid $color-grey-200;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: $mu100;

  .o-thumbnail__details {
    flex-direction: column;
    margin-bottom: $mu075;
  }

  .o-thumbnail__flag {
    @include thumbnail-price-policy;
  }

  .o-thumbnail__brand {
    height: $mu125;
  }

  .o-thumbnail__infos {
    margin-top: $mu200;
    margin-left: 0;
  }

  .o-thumbnail__price-infos {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: auto;
  }

  .o-thumbnail__add-to-cart {
    margin-top: $mu100;
    margin-left: 0;
  }
}

/* ########################################
            HORIZONTAL TO VERTICAL
   ######################################## */
.o-thumbnail--h-to-v {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include set-from-screen("m") {
    @include set-border-radius('l');

    border: 1px solid $color-grey-200;
    padding: $mu100;
  }

  .o-thumbnail__details {
    margin-bottom: $mu075;

    @include set-from-screen("m") {
      flex-direction: column;
    }
  }

  .o-thumbnail__flag {
    @include set-from-screen("m") {
      @include thumbnail-price-policy;
    }
  }

  .o-thumbnail__brand {
    height: $mu125;
  }

  .o-thumbnail__infos {
    @include set-from-screen("m") {
      margin-top: $mu200;
      margin-left: 0;
    }
  }

  .o-thumbnail__price-infos {
    @include set-border-radius('l');

    background-color: $color-primary-02-100;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: auto;
    padding: $mu100;

    @include set-from-screen("m") {
      background-color: inherit;
      padding: 0;
    }
  }

  .o-thumbnail__add-to-cart {
    @include set-from-screen("m") {
      margin-top: $mu100;
      margin-left: 0;
    }
  }
}

.o-thumbnail--h-to-v.-mobile {
  .o-thumbnail__price-infos {
    flex-direction: row;
    @include set-from-screen("m") {
      flex-direction: column;
    }
  }

  .o-thumbnail__price {
    display: flex;

    .kl-price {
      margin: auto 0;
    }
  }
}


/* ########################################
            ATOMS
   ######################################## */
.o-thumbnail {
  .a-illustration {
    @include set-border-radius('m');

    aspect-ratio: 1 / 1;
    position: relative;
    display: block;
    width: $mu800;
    height: $mu800;

    &--no-img {
      @include set-border-radius('m', 'top');

      background-color: $color-grey-100;
    }

    &__img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & svg {
      fill: $color-grey-300;
      width: $mu300;
      height: $mu300;
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.o-thumbnail--v {
  .a-illustration {
    @include set-border-radius('m', 'top');
    @include edge-bleed-image;

    &__img {
      @include set-border-radius('m', 'top');
    }
  }
}

.o-thumbnail--h-to-v {
  .a-illustration {
    @include set-from-screen("m") {
      @include edge-bleed-image;
    }

    &__img {
      @include set-from-screen("m") {
        @include set-border-radius('m', 'top');
      }
    }
  }
}


.o-thumbnail {
  .a-vendor {
    @include set-font-scale('03', 's');
  }

  .a-delivery {
    @include set-font-scale('04', 's');

    margin: 0;
  }

  .a-delivery + .a-delivery {
    margin-top: $mu025;
  }

  .a-designation {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: $color-grey-999;
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
    }

    &__label {
      @include set-font-scale('05', 'm');
      @include set-font-weight('semi-bold');

      display: block;
      height: 3.9rem;
      overflow: hidden;

      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        flex-direction: column;
        height: auto;

        /* stylelint-disable */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        /* stylelint-enable */
      }
    }
  }

  .a-reviews {
    @include set-font-scale('04', 'm');
  }

  .a-offers {
    @include set-font-scale('03', 'm');

    color: $color-font-light;
  }

  .a-repairability-index,
  .a-energy-label {
    height: $mu200;
    width: auto;
  }

  /* ########################################
            MOLECULES
   ######################################## */
  .m-variant {
    display: flex;
    flex-wrap: wrap;
    gap: 0 $mu025;

    &:not(:last-child) {
      margin-bottom: $mu050;
    }

    &__label {
      width: 100%;
      color: $color-font-dark;
      @include set-font-scale("04", "m");
    }

    &__choices {
      @include set-font-scale("04", "m");

      color: $color-font-dark;
    }

    &__plus {
      @include set-font-scale("04", "m");
      @include set-font-weight('semi-bold');

      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-grey-800;
      width: $mu250;
      height: $mu250;
      border-radius: get-border-radius("m");
      border: get-border("s") solid $color-grey-100;
      line-height: $mu250;
      margin-top: $mu025;
      background-color: $color-grey-100;
    }

    &__thumbnail {
      display: block;
      margin-top: $mu025;
      width: $mu250;
      height: $mu250;
      padding: get-border("s");
      border-radius: get-border-radius("m");
      border: get-border("s") solid $color-grey-100;
      cursor: pointer;
      position: relative;
      font-size: 0;

      &:is(span) {
        z-index: -1;
      }

      &:is(button):hover {
        border: get-border("s") solid $color-primary-01-700;
      }
      &:is(button):focus {
        border: get-border("s") solid $color-focus-border;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: get-border-radius("s");
      }

      svg:not(.kl-hidden) {
        width: 100%;
        height: 100%;
        background-color: $color-grey-100;
        fill: $color-grey-300;
        border-radius: get-border-radius("s");

        use {
          transform: scale(0.75);
          transform-origin: center;
        }
      }
    }
  }
}

.product-thumbnail {
  list-style-type: none;

  .th-relative {
    position: relative;
  }

  .th-relative .mc-flag__label {
    @include set-border-radius('s');
    @include set-font-weight('regular');

    position: absolute;
    left: -#{$mu050};
    top: $mu150;
    z-index: 1;
  }

  .button-round {
    width: $mu300;
    height: $mu300;
    border-radius: 50%;
    margin-bottom: $mu050;
  }

  .product-thumbnails-add-to-cart-msg {
    font-size: 14px;
    text-align: start;
    min-height: $mu125;
    max-width: 18rem;

    @include set-from-screen('m') {
      text-align: end;
      width: 10rem;
    }

    @include set-from-screen('l') {
      width: 20rem;
    }

    &__success {
      color: $color-font-success;
    }
  }

  .js-thumbnail-add-to-cart-light-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.product-thumbnails-atc-notification-fail {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 2;
  height: 100%;

  @include set-from-screen('m') {
    width: 288px;
    right: 24px;
  }

  .product-thumbnails-atc-add-fail {
    position: sticky;
    top: 0;
    padding: 1rem 2rem 1rem 1rem;

    @include set-from-screen('m') {
      padding: 1rem 1.5rem 1.5rem 1rem;
    }
  }
}

.arm-thumbnail-open-modal {
  position: relative;
  z-index: 1;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
