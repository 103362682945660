.stock-status {
  $sizes: 'xs' '03' 's', 's' '04' 'm', 'm' '05' 'xs', 'l' '06' 'xs';
  @each $size, $value, $lineHeight in $sizes {
    &--#{$size} {
      @include set-font-scale(#{$value}, #{$lineHeight});
    }
  }

  display: flex;
  align-items: flex-start;

  &_store {
    @include set-font-scale('04', 'm');
    font-weight: get-font-weight('semi-bold');
  }

  &_text {
    line-height: 1;
    display: flex;
    align-items: baseline;
    margin-bottom: $mu050;

    &-date {
      font-weight: get-font-weight('semi-bold');
    }

    &-container {
      @include set-font-scale('04', 'm');

      &--bold {
        font-weight: get-font-weight('semi-bold');
      }
    }

    &-content {
      margin-bottom: $mu050;
    }
  }

  &_badge {
    display: block;
    flex-shrink: 0;
    width: $mu050;
    height: $mu050;
    margin: 0 $mu050 0 0;
    border-radius: 50%;
    background-color: $color-grey-500;

    &--green {
      background-color: $color-success-500;
    }

    &--orange {
      background-color: $color-warning-500;
    }

    &--red {
      background-color: $color-danger-500;
    }

    &--grey {
      background-color: $color-grey-300;
    }
  }

  &_date {
    @include set-font-scale('04', 'm');
    font-weight: get-font-weight('regular');
  }
}

.js-th-display-availabilities {
  position: relative;
  cursor: pointer;
  text-decoration: underline;
}
